import React from 'react';
import Link from 'next/link';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from './styles';
import { FaHome } from 'react-icons/fa';
import { imageConstraints } from '../../../utils/constraints';

const NotFoundPage = () => {
  return (
    <Styles>
      {/* Main Wrapper */}
      <div className="main-wrapper error-page">
        {/* 404 Area */}
        <section
          className="error-area"
          style={{
            backgroundImage: `url(${imageConstraints['Not-Found-Page-Background']})`,
          }}
        >
          <Container>
            <Row>
              <Col md="12">
                <div className="error-box text-center">
                  <h1>
                    4<span>0</span>4
                  </h1>
                  <h3>Página não encontrada</h3>
                  <p>Ooops! A página que você está procurando não foi encontrada.</p>
                  <Link href="/">
                    <FaHome /> Voltar
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
};

export default NotFoundPage;
